.game-tile-root {
    min-width: 110;
    z-index: 1000;
}
.game-tile-card-content {
    display: flex; 
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;    
}
.game-tile-media {
    height: 0;
    padding-top: 56.25%;
    margin-top: 10px;
}