.dashboard {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
}

.rtl {
    direction: rtl;
}
.ltr {
    direction: ltr;
}

@media (min-width: 76.25em) {
    .dashboard {
        /* padding: 2rem 1.25rem; */
    }
}