.stats-cat-card {
    display: flex;
    margin: 10px;
}
.stats-cat-card-content {
    flex: '1 0 auto';
}
.stats-cat-card-content-details {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
}
.stats-cat-card-content-image {
    width: 75%;
    height: 75%;
    background-size: contain !important;
}
.stats-cat-card-content-media {
    width: 150px;
    flex-direction: column;
    align-items: flex-start;
    display:flex;
    justify-content: center;
    align-items: center;
}
.stats-cat-card-content-status {
    display:flex; 
    justify-content:flex-end;
    line-height:2vh;
}
.stats-cat-card-content-word {
    font-weight: bold !important;
}

.stats-cat-card-content-status-Solved {
    color: #92D050;
}
.stats-cat-card-content-status-Mistake {
    color: #CC503E;
}
.stats-cat-card-content-status-Pending {
    color: #1E2125;
}
.stats-cat-card-content-date {
    display:flex;
    line-height:2vh;
}
.stats-cat-card-content-time {
    color: #5A88C7; 
    display:flex;
    line-height:2vh;
}