.app {
  width: 100%;
}
.content {
  min-height: calc(100vh - 65px);
  background-color: #f5f5f5;
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  /*direction: rtl;*/
  display: flex;
  justify-content: flex-end;
  z-index: 10000;
  opacity: 1.0;
  display:flex;
  align-items:center;
  background: black;
  width: 100%;
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: calc(0.7em + 1vw);
}
.logo {
  margin: 0 auto;
  padding: 0px 10px;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}