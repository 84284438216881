.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 1rem;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color: #000;
    background-color: #fff;
    z-index: 10000;
}
.footer-heart {
    color: #FF2800;
}
.footer-line {
    height:1px;
    width:100%;
    background: #000;
}
.footer-text {
    padding: 0.5rem;
    font-size: 0.8rem;
    text-align: center;
    /*direction: rtl;*/
}