.stats-tile-root {
    min-width: 250;  
}
.stats-tile-card-content {
     
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around; 
}
.stats-tile-title {
    width: 100%;
}

.stats-tile-card-title1-negative {
    color: #f50057;
}
.stats-tile-card-title1-positive {
    color: #92D050;
}

.stats-tile-values {
    font-weight: bold;
    width: 100%;
}
.stats-tile-type-text-text {
    font-weight: bolder;
    width: 100%;
}
.stats-tile-type-text-number {
    display: none;
}
.stats-tile-type-number-number {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}
.stats-tile-type-number-text {
    display: none;
}