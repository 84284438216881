.menu-item-container {
    opacity: 0;
    animation: 1s appear forwards;
    animation-delay:10;
}
.menu-item {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    padding: 1rem 0;
    margin: 0 5%;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    animation: 0.5s slideIn forwards;
    animation-delay: 10;
}
.menu-item-hover {
    color: gray;
}
.menu-item-no-hover {
    color: #fafafa;
}
.menu-item-line {
    width: 90%;
    height: 1px;
    background: gray;
    margin: 0 auto;
    animation: 0.5s shrink forwards;
    animation-delay:10;
}