.home {
    padding: 2rem 1rem;
    min-height: calc(100vh - 65px);
}

.game-body {
    padding-top: 20px;
    /*direction: rtl;*/
    display: flex;
    flex-direction: column;
    align-items: center;
}
.particles-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
}