.home {
    padding: 2rem 1rem;
    min-height: calc(100vh - 65px);
    background-color: #fafafa;
}

.stats-body {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}
.stats-body-tiles {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table-body {
    font-size: 4vw;
    min-width: 700;
    border-spacing: 0 10px !important;
    border-collapse: separate !important;
    background-color: #fff;
}

.table-row-media {
    height: 4vh;
    width: 4vw;
}

.table-cell-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.table-cell-ratio p {
    color: #98999C;
    font-size: 12px;
    font-weight: Bold;
}