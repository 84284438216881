.letter {
    direction: rtl;
    width: 13%;
}
.letter input {
    text-align: center;
    color: #151E3D;
    font-size: 5vh;
    padding: 2px 2px;
}

.emptyletter fieldset {
    border: none;
}