.card-content {
    display: flex; 
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0px 0px 0px !important;
}
.card-content-solved {
    display: flex; 
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0px 0px 0px !important;
}
.card-content-solved input {
    text-align: center;
    color: #500b2d;
    font-size: 4vw;
    padding: 2px 2px;
}
.game-card {
    max-width: calc(65vh - 65px);
    z-index: 1000;
}
.game-card button {
    padding: 10px;
    background: #fff !important;
}
.game-card-media {
    margin-top: 10px;
}
.actions {
    padding: 1px;
    width: 100%;
    margin: 1px;
    display:flex;
    justify-content:space-between; 
    border-top: #000 1px solid;
}
.confetti {
    z-index: 5000 !important;
    top: 40px !important;
}
.trophy {
    z-index: 1000;
    min-height: 5vh !important;
}
.game-card-focus-visible {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}