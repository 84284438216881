.menu-button-container {
    height: 32px;
    width: 32px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
}
.menu-button-line {
    height: 2px;
    width: 20px;
    background: #fff;
    transition: all 0.2s ease;
}
.menu-button-line-top {
    transform-origin: top left;
    margin-bottom: 5px;
}
.menu-button-line-top-open {
    transform: rotate(45deg);
}
.menu-button-line-top-close {
    transform: none;
}
.menu-button-line-middle-open {
    opacity: 0;
    transform: translateX(-16px);
}
.menu-button-line-middle-close {
    opacity: 1;
    transform: none;
}
.menu-button-line-bottom {
    transform-origin: top left;
    margin-top: 5px;
}
.menu-button-line-bottom-open {
    transform: translateX(-1px) rotate(-45deg)
}
.menu-button-line-bottom-close {
    transform: none
}