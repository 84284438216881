.about {
    padding: 2rem 1rem;
    min-height: calc(100vh - 65px);
    white-space: pre-line;
}
.about-body {
    padding-top: 20px;
    /*direction: rtl;*/
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title {
    font-size: 14;
}
.pos {
    margin-bottom: 12;
}
.about-media{
    height: 0;
    padding-top: 56.25%;
}
.about-card-root {
    z-index: 200;
}
.player-wrapper {
    display: flex; 
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}