.menu-container {
    position: absolute;
    top: 0;
    left: 0;
    /*direction: rtl;*/
    display: flex;
    width: 100vw;
    flex-direction: column;
    background: black;
    opacity: 0.95;
    color: #fafafa;
    transition: height 0.3s ease;
    z-index: 5000;
}
.menu-height-100 {
    height: 100%;
}
.menu-height-0 {
    height: 0;
}

.menu-list {
    padding-top: 3rem;
}