.home {
    padding: 2rem 1rem;
    min-height: calc(100vh - 65px);
    background-color: #fafafa;
}

.stats-body {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stats-cat-title {
    text-transform: uppercase;
}

.stats-cat-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    background-color: #fff;
    border-bottom: 0.5px solid rgb(43, 43, 43);
}

.stats-cat-filters {
    display: flex;
    justify-content: flex-start;
    padding-top: 1vh;
    width: 100%;
}

.stats-cat-form-control {
    margin: 0px 10px !important;
    min-width: 120px !important;
}

.stats-cat-body {
    padding-top: 20px;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin: 1px;
    display:flex;
    justify-content:space-between;
    background-color: #fff;
}